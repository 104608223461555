import React from 'react'
import { Link } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import ThemeContext from "../context/ThemeContext"

const Menu = class extends React.Component {
  render() {
    const prevPage = this.props.location && this.props.location.state && this.props.location.state.currentPage ? this.props.location.state.currentPage : '/'
    return (
      <ThemeContext.Consumer>
        {theme => (
          <div className="cMenu cSplitScreen">
            <div className="cSplitScreen-content">
              <div className="cSplitScreen-heading">
                <div className="cSplitScreen-icon">
                  <img src="/img/stack-of-books.png" alt="stack of books illustration" />
                </div>
                <p className="cSplitScreen-title">Cleo's Books</p>
                <AniLink fade to={ prevPage ? prevPage : '/'} duration={0.25} className="close-icon" aria-label="Close"></AniLink>
              </div>
              <div className="cSplitScreen-path">
                <div className="cSplitScreen-icon">
                  <img src="/img/stack-of-books.png" alt="stack of books illustration" />
                </div>
                <p className="cSplitScreen-path-text">Menu</p>
                <AniLink fade to={ prevPage ? prevPage : '/'} duration={0.25} className="close-icon" aria-label="Close"></AniLink>
              </div>
              <div className="cMenu-body">
                <div className="cMenu-list">
                  {/* <div className="cMenu-item">
                    <Link className="cMenu-link" to="/city/new-york-city">
                      Explore the Map
                    </Link>
                  </div> */}
                  {/* <div className="cMenu-item">
                    <AniLink fade duration={0.25} className="cMenu-link" to="/story" state={{ prevPage: prevPage }}>
                      Places &amp; People
                    </AniLink>
                  </div> */}
                  <div className="cMenu-item">
                    <AniLink fade duration={0.25} className="cMenu-link" to="/the-index" state={{ prevPage: prevPage }}>
                      The Index
                    </AniLink>
                  </div>
                  <div className="cMenu-item">
                    <AniLink fade duration={0.25} className="cMenu-link" to="/about">
                      About
                    </AniLink>
                  </div>
                  {/* <div className="cMenu-item">
                    <AniLink fade duration={0.25} className="cMenu-link" to="/shop">
                      Shop
                    </AniLink>
                  </div> */}
                  {theme.checkout && theme.checkout.lineItems && theme.checkout.lineItems.length ? (
                  <div className="cMenu-item cMenu-item--cart">
                    <AniLink fade duration={0.25} className="cMenu-link" to="/cart">
                      Cart
                      <span className="cMenu-cartCount"><span>{ theme.checkout.lineItems.length }</span></span>
                      <div className="cMenu-cartAmount">${(theme.checkout.subtotalPriceV2.amount * 1).toFixed(2) }</div>
                    </AniLink>
                  </div>
                  ): null }
                  <div className="cMenu-item">
                    <Link className="cMenu-link" to="/">
                      Home
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <AniLink fade to={ prevPage ? prevPage : '/'} duration={0.25} className="cSplitScreen-cover">
              <h2 className="cSplitScreen-cover-title">Cleo's Books</h2>
            </AniLink>
          </div>
          )}
      </ThemeContext.Consumer>
    )
  }
}

export default Menu
